import React from "react";

const BackgroundImgBanner = () => {
  return (
    <>
      <section className="BackgroundImgBanner">
        <h3>research is key to development and innovation</h3>
      </section>
    </>
  );
};

export default BackgroundImgBanner;
