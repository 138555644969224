import React from "react";

const Donate = () => {
  return (
    <>
      <section className="donate">
        <div className="content">
          <h3>
            support Aiperdlbg cause by donating to our mobile money number:
          </h3>
          <h1>+233 508960822</h1>
        </div>
      </section>
    </>
  );
};

export default Donate;
